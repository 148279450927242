import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ElementUI from 'element-ui';
import MuseUI from 'muse-ui';
import VueClipboard from 'vue-clipboard2';
import FlashMessage from '@smartweb/vue-flash-message';
import store from './store'
import App from './App.vue'
import router from './router'
import 'element-ui/lib/theme-chalk/index.css';
import 'muse-ui/dist/muse-ui.css';

// Heroku 版本升級 

Vue.config.productionTip = false

Vue.use(MuseUI);
Vue.use(ElementUI);
Vue.use(VueAxios, axios);
Vue.use(FlashMessage);
Vue.use(VueClipboard);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
