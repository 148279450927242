<template>
  <div style="padding: 20px; padding-bottom: 45px;">
    <div style="text-align: left; margin-bottom: 5px;">
      <h3 style="color: #466594; margin-top: 0; margin-bottom: 10px;">篩選條件</h3>
      <el-row :gutter="10" style="max-width: 1000px;">
        <!-- 選擇環境 -->
        <el-col :span="6">
          <el-select style="margin-bottom: 10px; width: 100%;" v-model="enviro" placeholder="請選擇環境" @change="clearData">
            <el-option v-for="item in enviros"
              :key="item.value"
              :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <!-- 選擇品牌 -->
        <el-col :span="6">
          <el-select style="margin-bottom: 10px; width: 100%;" @change="getSaleroom" v-model="client" placeholder="請選擇品牌">
            <el-option v-for="item in clientList"
              :key="item.clientId"
              :label="item.name"
              :value="item.clientId">
            </el-option>
          </el-select>
        </el-col>
        <!-- 選擇店櫃 -->
        <el-col :span="6" v-if="salerooms[client]">
          <el-select style="margin-bottom: 10px; width: 100%;" v-model="saleroom" placeholder="選擇店櫃">
            <el-option v-for="option in salerooms[client]"
              :key="option.no"
              :label="option.name"
              :value="option.no">
            </el-option>
          </el-select>
        </el-col>
        <!-- 電話 -->
        <el-col :span="6">
          <el-input v-model="phone" placeholder="輸入會員電話"></el-input>
        </el-col>
      </el-row>
      <!-- 起始日 -->
      <hr style="border: 1px solid #eee;">
      <h3 style="color: #466594; margin-bottom: 10px;">時間區間</h3>
      <el-date-picker v-model="start"
        style="width: 195px;"
        align="right"
        type="date"
        placeholder="選擇起始日"
        :picker-options="pickerOptions">
      </el-date-picker> -
      <!-- 到期日 -->
      <el-date-picker v-model="end"
        style="width: 195px; margin-right: 10px;"
        align="right"
        type="date"
        placeholder="選擇到期日"
        :picker-options="pickerOptions">
      </el-date-picker>
      <el-button type="primary" icon="el-icon-search" :disabled="!client" @click="getTransaction">查詢</el-button>
    </div>
    <div>
      <el-table v-if="isData" height="calc(100vh - 400px)" :key="pageTab" :data="orderList[pageTab - 1]" :header-cell-style="tableHeaderColor" empty-text="暫無資料">
        <el-table-column prop="date"
          label="時間" width="120">
          <template slot-scope="scope">
            <div>{{ getFormatDate(scope.row.date) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="orderId"
          label="訂單編號" width="240">
        </el-table-column>
        <el-table-column prop="userId"
          label="會員編號" width="150">
        </el-table-column>
        <el-table-column prop="name"
          width="130"
          label="姓名">
        </el-table-column>
        <el-table-column prop="phone"
          width="130"
          label="電話">
        </el-table-column>
        <el-table-column prop="amount"
          width="130"
          label="消費金額">
        </el-table-column>
        <el-table-column prop="getPoint"
          width="130"
          label="獲得點數">
        </el-table-column>
        <el-table-column prop="storeId"
          label="消費櫃位">
        </el-table-column>
      </el-table>
      <div style="position: relative; margin-top: 10px; margin-bottom:10px;" v-if="isData">
        <mu-pagination style="position: absolute; right: 0; top: 0px;" @change="changePage" raised circle :total="totalOrders" :current.sync="pageTab"></mu-pagination>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      phone: '',
      enviro: 'pro',
      client: '',
      nowEdit: '',
      totalNum: 0,
      totalOrders: 0,
      pageTab: 1,
      isData: false,
      start: Date.now(),
      end: Date.now(),
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
      orderList: [],
      saleroom: '',
      // 這邊要改用 config
      salerooms: {
        '1654333455': [
          { no: '無', name: '全省' }
        ],
        '1655280896': [
          { no: '無', name: '全省' }
        ]
      },
      enviros: [
        { value: 'pro', name: '正式站' },
        { value: 'test', name: '測試站' }
      ],
    };
  },
  computed: {
    ...mapGetters({
      clientList: 'clients'
    })
  },
  mounted() {
    if (this.clientList && this.clientList.length > 0) {
      // do something
    }
    else {
      this.getBrands();
    }
  },
  methods: {
    ...mapActions({
      getBrands: 'getBrands',
      getTransactionRecord: 'getTransactionRecord',
      getClientData: 'getClientData'
    }),
    changePage(val) {
      this.pageTab = val;
    },
    getFormatDate(date) {
      if (date) {
        const year = date.substr(0, 4);
        const month = date.substr(4, 2);
        const day = date.substr(6, 2);

        return `${year}-${month}-${day}`;
      }
      return '-';
    },
    getSaleroom() {
      this.getClientData({ clientId: this.client, enviro: this.enviro }).then((_data) => {
        if (_data.status === '10020') {
          this.salerooms[this.client] = _data.result.salerooms ? _data.result.salerooms : [];
        }
      })
    },
    getTransaction() {
      this.pageTab = 1;
      this.orderList = [];
      this.isData = false;
      const postData = {
        clientId: this.client,
        enviro: this.enviro,
        dateStart: this.getFullDate(this.start),
        dateClose: this.getFullDate(this.end),
        phone: this.phone,
        type: 'all',
        storeId: this.saleroom === '無' ? '' : this.saleroom
      };
      const loading = this.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' });
      this.getTransactionRecord(postData)
        .then((res) => {
          loading.close();
          if (res.status === '10020') {
            this.totalOrders = res.result.length;
            this.totalNum = Math.floor(res.result.length / 10) + 1;
            for (let i = 0; i < this.totalNum; i += 1) {
              this.orderList[i] = [];
            }
            for (let e = 0; e < res.result.length; e += 1) {
              const index = e;
              const group = parseInt(index / 10);
              const orderData = res.result[index];
              this.orderList[group].push(orderData)
            }
            this.isData = true;
          }
        })
    },
    clearData() {
      this.orderList = [];
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #eee; font-weight: 500; color: #444;'
      }
    },
    getFullDate(time) {
      const date = time ? new Date(time) : new Date();
      const year = date.getFullYear();
      const month = (date.getMonth() + 1) < 10 ? `0${(date.getMonth() + 1).toString()}` : (date.getMonth() + 1).toString();
      const day = date.getDate() < 10 ? `0${date.getDate().toString()}` : date.getDate().toString();
      return `${year}${month}${day}`;
    }
  }
}
</script>

<style scoped>

</style>